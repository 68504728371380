var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("multi-select", {
    attrs: {
      value: _vm.selectedJurisdiction,
      label: "name",
      "track-by": "value",
      options: _vm.options,
      disabled: _vm.isDisable,
      placeholder: _vm.placeholder,
    },
    on: { input: _vm.jurisdictionSelected },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }