<template>
  <multi-select
    :value="selectedJurisdiction"
    label="name"
    track-by="value"
    :options="options"
    :disabled="isDisable"
    :placeholder="placeholder"
    @input="jurisdictionSelected"
  />
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import MultiSelect from 'vue-multiselect'

  export default {
    name: 'JurisdictionSelect',

    components: {
      MultiSelect,
    },

    props: {
      isDisable:{
        type: Boolean,
        default: true,
      },
      placeholder: {
        type: String,
        default: 'Select a jurisdiction',
      },
      resetJurisdiction: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        loading: true,
        options: [],
        selectedJurisdiction: null,
      }
    },

    computed: {
      ...mapGetters('jurisdictions', ['formsLibraryJurisdictions', 'jurisdictionsLoaded']),
    },

    watch: {
      resetJurisdiction: function() {
        this.jurisdictionSelected(null)
        this.$emit('resetJurisdiction', false)
      },
    },

    async mounted() {
      if (!this.jurisdictionsLoaded) {
        await this.load()
      }

      this.options = this.formsLibraryJurisdictions.map(j => ({ name: j.state_province_region, value: j.id }))
    },

    methods: {
      ...mapActions('jurisdictions', ['load']),
      jurisdictionSelected(selection) {
        this.selectedJurisdiction = selection
        this.$emit('input', selection?.value)
      },
    },
  }
</script>

<style>
  .multiselect__select {
    height: 41px;
  }

</style>
